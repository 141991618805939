
import Vue from 'vue';
import Vuex from 'vuex';
import rootStore from "coremarine-frontend-lib/store";
import AuthManager from "coremarine-frontend-lib/apitools/UsersAPIAuthManager";

import './bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import router from './router';
import modulesStore from "./store";
import App from './App.vue';
import ModulePlugin from "coremarine-frontend-lib/utils/ModulePlugin";

Vue.config.productionTip = false;
Vue.use(Vuex);

Vue.use(ModulePlugin, {
    modules: {
        mission_planner: {
            store: () => import("mission-planner-frontend/src/store"),
            routes: () => import("mission-planner-frontend/src/routes/user")
        },
        mission_planner_admin: {
            store: () => import("mission-planner-frontend/src/store"),
            routes: () => import("mission-planner-frontend/src/routes/admin")
        },
        frank: {
            store: () => import("frank-frontend/src/store"),
            routes: () => import("frank-frontend/src/routes/user")
        },
    }
});

Vue.prototype.setFocus = function setFocus(timeout=false) {
    if(timeout) {
        Vue.nextTick(() => this.setFocus());
        return;
    }
    const els = ["a", "input", "button", "select", "textarea", "[tabindex]"];
    const not = ':not([tabindex="-1"])';
    const selector = els.map(e => e + not).join(", ");
    if(!this.$el.querySelector) return;

    const el = this.$el.querySelector(selector);

    if(el) {
        el.focus();
    }
};

const store = new Vuex.Store(rootStore);
store.registerModule("modules", modulesStore);

store.dispatch("initialize", {
    authManager: new AuthManager("/api/"),
    config: {
        app: {
            name: "CoreDigital",
            version: "",
            display: {
                feedback: true,
                userMenu: true
            },
            feedbackLabels:  ["coreDigital"],
            feedbackJiraKey: "IOPS"
        },
        feedback: {
            url: "/api/feedback",
            enable: true
        },
    }
}).then(() => {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
});


