<template>
    <div class="index index w-100 h-100">
        <AppLayout
            ref="app"
            :name="app.name"
            :version="app.version"
            :changelog="app.changelog"
            :feedback="app.display.feedback"
            :user-menu="app.display.userMenu"
            @send-feedback="sendFeedback"
        >
            <template #navbar-content>
                <slot name="navbar-content" />
            </template>
            <template #body>
                <router-view />
            </template>
        </AppLayout>
    </div>
</template>

<script>
import AppLayout from "coremarine-frontend-lib/components/AppLayout";
import {mapState} from "vuex";

export default {
    name: 'IndexView',
    components: {
        AppLayout
    },
    beforeRouteUpdate(to, _, next) {
        if(to.meta?.storeModule) {
            this.storeModule = to.meta.storeModule;
        }
        next();
    },
    data() {
        return {
            loading: true,
            storeModule: this.$route.meta?.storeModule || "main"
        };
    },
    computed: {
        ...mapState("config", ["app"]),
        ...mapState("user", ["user"]),
        moduleInfo() {
            if(this.storeModule === "main" || !this.storeModule) {
                return this.app;
            }
            return this.$store.state[this.storeModule].config.app;
        }
    },
    methods: {
        sendFeedback(data) {
            const {name: moduleName, version: moduleVersion} = this.moduleInfo;
            const {name: appName, version: appVersion} = this.app;
            data.appInfo = {
                moduleName, moduleVersion,
                appName,  appVersion,
            };

            data.jkey = this.app.feedbackJiraKey;
            data.labels = this.app.feedbackLabels;
            this.$store.dispatch("sendFeedback", data)
                .then(res =>
                    this.$refs.app.feedbackResponse(res)
                );
        },
    }
};
</script>
<style lang="scss">
@import "coremarine-frontend-lib/styles";

</style>
