
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Login from "../views/Login";
import Index from "../views/Index";
import extendRouter from "coremarine-frontend-lib/utils/extendRouter";
import icon from "src/assets/icons/favicon.png";

const routes = [{
    path: "/",
    name: "index",
    component: Index,
    children: [{
        path: "",
        name: "index_modules_list",
        component: () => import("../components/ModulesList"),
        meta: {
            icon,
            storeModule: "main"
        }
    }],
    meta: {
        title: ["Index"]
    }
}, {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Admin"),
    children: [{
        path: "",
        name: "admin_main",
        component: () => import("../components/AdminMain.vue"),
        meta: {
            title: ["Main"]
        }
    }],
    meta: {
        title: ["Admin panel"]
    }
}, {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
        title: ["Login"]
    }
}, {
    path: "*",
    component: () => import("coremarine-frontend-lib/components/NotFound")
}];


const router = extendRouter(routes, new VueRouter({routes}), "CoreDigital");

export default router;