const display = {
    feedback: true,
    userMenu: true
};

const tileSources = {
    base: {
        url: "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    satellite: {
        url: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?" +
        "access_token=pk.eyJ1IjoidmFzeWE2NjYiLCJhIjoiY2txa2JvZm44MDJjMjJvbzJ5aDg1ZTAycSJ9.PYNlvhVHyLq8njxjuM3Vxw"
    }
};


export default [{
    parent: "index",
    entrypoint: {name: "planner_index"},
    key: "mission_planner",
    name: "missionPlanner",
    path: "/mission-planner",
    label: "Mission Planner",
    storeModule: "missionPlanner",
    type: "user",
    icon: "mission-planner",
    description: "CoreDigital Planning Module",
    config: {
        app: {
            apiUrl: "/mission-planner/api/",
            feedbackLabels:  ["missionPlannerFeedback"],
            feedbackJiraKey: "IOPS"
        },
    }
},{
    parent: "admin",
    entrypoint: {name: "planner_admin"},
    key: "mission_planner_admin",
    name: "missionPlannerAdmin",
    path: "/admin/mission-planner",
    label: "Mission Planner",
    storeModule: "missionPlanner",
    type: "admin",
    icon: "mission-planner",
    description: "Mission Planner admin panel",
    config: {
        app: {
            apiUrl: "/mission-planner/api/",
            feedbackLabels:  ["missionPlannerFeedback"],
            feedbackJiraKey: "IOPS"
        },
    }
}, {
    parent: "index",
    entrypoint: {name: "frank_new_project"},
    key: "frank",
    name: "frank",
    path: "/frank",
    label: "FRANK",
    storeModule: "frank",
    type: "user",
    icon: "frank",
    description: "CoreDigital Design Module",
    config: {
        app: {
            apiUrl: "/frank/api/",
            feedbackLabels:  ["frankFeedback"],
            feedbackJiraKey: "FRNK"
        },
    }
},  {
    parent: "index",
    key: "iops",
    name: "iops",
    path: "/iops",
    label: "IOPS",
    storeModule: "iops",
    type: "user",
    icon: "iops",
    description: "CoreDigital Operations Module",
    config: {}
}, {
    parent: "index",
    key: "core-integrity",
    name: "coreIntegrity",
    path: "/core-integrity",
    label: "CoreIntegrity",
    storeModule: "coreIntegrity",
    type: "user",
    icon: "core-integrity",
    description: "CoreDigital Integrity Module",
    config: {}
},];