import MODULES from "../config/modules";


export default {
    namespaced: true,
    state: {
        userModules: MODULES.filter(m => m.type === "user"),
        adminModules: [],
        enabledModules: {}
    },
    mutations: {
    },
    actions: {
        loadList({state, rootState}) {
            if(rootState.user.user?.loggedIn) {
                const {admin, permissions} = rootState.user.user;
                state.adminModules = admin ?
                    MODULES.filter(m => m.type === "admin")
                    : [];
                state.enabledModules = Object.fromEntries(
                    MODULES.filter(m =>
                        m.type === "user" &&
                        permissions[m.key] &&
                        permissions[m.key] !== "none"
                    ).map(m => [m.key, m])
                );
            }
        }
    },
    modules: {
    }
};
