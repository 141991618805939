<template>
    <div
        id="app"
        class="h-100"
    >
        <transition
            name="animated"
            :duration="duration"
        >
            <router-view>
                <template #navbar-content>
                    <template v-if="!loading">
                        <router-link
                            v-if="isAdmin && currentView == 'user'"
                            :to="{name: 'admin'}"
                        >
                            <b-btn
                                class="mx-4"
                                style="white-space: nowrap; width:125px"
                            >
                                Admin panel
                            </b-btn>
                        </router-link>
                        <router-link
                            v-if="currentView == 'admin'"
                            :to="{name: 'index_modules_list'}"
                        >
                            <b-btn
                                class="mx-4"
                                style="white-space: nowrap; width:125px"
                            >
                                Index page
                            </b-btn>
                        </router-link>
                        <b-nav class="module-nav">
                            <b-nav-item-dropdown
                                text="Modules"
                                class="module-dropdown"
                            >
                                <b-dropdown-item
                                    v-for="mod in displayModules"
                                    :key="mod.key"
                                    :to="mod.entrypoint"
                                >
                                    <i
                                        class="module-icon"
                                        :class="mod.icon"
                                    />
                                    <span>{{ mod.label }}</span>
                                </b-dropdown-item>
                            </b-nav-item-dropdown>

                            <b-nav-item
                                v-for="mod in displayModules"
                                :key="mod.key"
                                :to="mod.entrypoint"
                                class="module-item-plain"
                            >
                                <i
                                    class="module-icon"
                                    :class="mod.icon"
                                />
                                <span>{{ mod.label }}</span>
                            </b-nav-item>
                        </b-nav>
                    </template>
                    <template v-else>
                        <div class="d-flex align-items-center loading-modules">
                            <span>Loading modules...</span>
                            <Loading />
                        </div>
                    </template>
                </template>
            </router-view>
        </transition>
    </div>
</template>
<script>
import {mapState} from "vuex";
import Loading from "coremarine-frontend-lib/components/LoadingSmall";


export default {
    components: {
        Loading
    },
    data() {
        return {
            prevRoute: ""
        };
    },
    computed: {
        ...mapState("user", ["user"]),
        ...mapState("modules", ["adminModules"]),
        isAdmin() {
            return this.user.admin;
        },
        loggedIn() {
            return this.user.loggedIn;
        },
        currentView() {
            return ~this.$route.name?.indexOf("admin") ?
                "admin"
                : "user";
        },
        duration() {
            return this.prevRoute == "login" ? 1200 : 0;
        },
        displayModules() {
            return this.modules.filter(m => m.type == this.currentView);
        },
        modules() {
            return [
                ...Object.values(this.$store.state.modules.enabledModules),
                ...this.adminModules
            ];
        }
    },
    watch: {
        loggedIn(cur, prev) {
            if(!cur && prev) {
                this.$unregisterAllModules();
                this.toLoginPage();
            } else if(cur && !prev) {
                this.checkAuth();
            }

        },
        $route(r, prev) {
            this.prevRoute = prev.name;
            if(!this.loggedIn) {
                this.checkAuth();
            }
        }
    },
    created() {
        this.checkAuth();
    },
    methods: {
        checkAuth() {
            this.loading = true;
            this.$store.dispatch("user/auth")
                .then(() => {
                    if(!this.user.loggedIn) {
                        this.toLoginPage();
                    } else {
                        if(this.$route.name == "login") {
                            this.$router.push({name: "index"});
                        }
                        this.registerUserModules();
                    }
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.toLoginPage();
                    this.loading = false;
                });
        },
        hasAccessToModule(m) {
            return this.user?.permissions[m.key] &&
                this.user.permissions[m.key] !== "none";
        },
        registerUserModules() {
            this.$store.dispatch("modules/loadList");

            try{
                this.modules.forEach(m => this.$registerModule(m));
            } catch (e) {
                console.log(e);
            }
        },
        toLoginPage() {
            if(this.$route.name !== "login") {
                this.$router.push({name: "login"});
            }
            this.$unregisterAllModules();
        }
    }
};
</script>
<style lang="scss">
@import "coremarine-frontend-lib/styles";
@import "coremarine-frontend-lib/scss/nav-tabs";
@import "coremarine-frontend-lib/scss/dropdown";


.not-found {
    height: 75%
}

.nav.module-nav {
    flex-wrap: nowrap!important;
    flex: 1 1 50%;
  //  margin-top: -16px;
    margin-bottom: -11px;
    display: flex;
    align-items: stretch;
   position: relative;
    .module-item-plain {
        display: inline-block;
         min-width: 200px;
         height: 60px;
         text-align: center;
         a {
             height: 100%;
             display: flex;
             align-items: center;
             justify-content: center;
         }
    }
    border-bottom: none!important;

    .nav-link {
        box-sizing: border-box;
        border-bottom: 3px solid transparent!important;
        padding-bottom: 16px;
        &.router-link-active {
            //border-color: $color-accent!important;
            background: $color-bg-lighter!important;
        }
    }
    .module-dropdown {
        display: none;
        .nav-link {
            padding-bottom: unset;
        }
    }

}
.module-icon {
    background: {
        repeat: no-repeat;
        position: center;
        size: contain;
    }
    &.mission-planner {
        background-image: url("./assets/img/location.png");
    }
    &.frank {
        background-image: url("./assets/img/wind-turbine.png");
    }
    &.iops {
        background-image: url("./assets/img/pulse.png");
    }
    &.core-integrity {
        background-image: url("./assets/img/anchor-1.png");
    }
}
.loading-modules {
    opacity: .8;
    span {
        white-space: nowrap;
    }
    .spinner-border {
        width: 1.5em;
        height: 1.5em;
        margin-left: 8px;
    }
}
@media screen and (max-width: $screen-width-md) {
    .nav.module-nav {
        .module-item-plain {
            display: none;
        }
        .module-dropdown {
            display: inline-block;
        }
    }
}
body {
    overflow: hidden;
}
</style>
