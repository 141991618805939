<template>
    <LoginPage>
        <template #append>
            <DropWave />
        </template>
    </LoginPage>
</template>

<script>
import LoginPage from "coremarine-frontend-lib/components/Login";
import DropWave from "../components/DropWave";

export default {
    components: {
        LoginPage,
        DropWave,
    }
};
</script>
<style lang="scss">
@import "coremarine-frontend-lib/styles";

#app {
    min-width: 100vw;
}

#login-page {
    position: absolute;
    z-index: 10000000;
    .drop-wave {
        display: none;
        transition: opacity .5s linear;
        opacity: 0;
        z-index: 10000;
    }

    #login {
        transition: 1s opacity ease-in-out;
    }
    &.animated-leave-active {
        .drop-wave {
            display: block;
            opacity: 1;
        }
    }
    &.animated-leave-to {
        #login {
            opacity: 0;
        }
    }
}
</style>